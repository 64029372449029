<template>
	<div>
		<Header ref="Header" />
		<div class="container">
			<div class="passage-tips flex">
				<div class="jump" @click="toPage()">{{showData.categoryName}}</div>
				<img class="passage-tips-arrow" src="../../public/images/arrow_3.png">
				<!-- <div v-if="headerIndex==3" class="passage-tips-title">{{showData.categoryName}}</div>
				<img v-if="headerIndex==3" class="passage-tips-arrow" src="../../public/images/arrow_3.png"> -->
				<div>正文</div>
			</div>
			<div class="passage-title">{{showData.title}}</div>
			<div class="passage-time row">
				<div>{{showData.createTime}}</div>
				<div class="passage-view">浏览 {{showData.viewCount}}</div>
			</div>
			<div class="passage-list">
				<div v-html="showData.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	export default {
		components: {
			Header
		},
		data() {
			return {
				showData: {

				},
				id:0,
				headerIndex:0,
				indexList:[
					{name:'专题推荐',herf:'topic',headerIndex:2},
					{name:'服务案例',herf:'service',headerIndex:3},
					{name:'最新动态',herf:'dynamic',headerIndex:4},
				],
			}
		},
		methods: {
			toPage(){
				for(let i=0;i<this.indexList.length;i++){
					if(this.headerIndex==this.indexList[i].headerIndex){
						this.$router.push({
							path:this.indexList[i].herf
						})
					}
				}
			},
		},
		mounted() {
			if(this.$route.query.index){
				this.headerIndex=this.$route.query.index;
			}
			this.$refs.Header.setIndex(this.$route.query.index);
			this.id=this.$route.query.id || 0;
			if(this.id<0){
				alert('传参有误');
				this.$router.go(-1);
			}
			this.$api.getArticleDetail(this.$route.query.id).then(Artres => {
				console.log(Artres, '对应文章');
				// Artres.data.content = Artres.data.content.replace(/img\s/ig, 'img style="margin:0 auto;"');
				// Artres.data.content = Artres.data.content.replace(/img\s/ig, 'img style="width:100%;height:auto"');
				this.showData = Artres.data;
			});
			
		}
	};
</script>

<style scoped>

</style>
